import React from "react";

import ContactForm from "../components/ContactForm";
import DefaultLayout from "../components/layouts/DefaultLayout";
import SEO from "../components/layouts/SEO";
import Hero from "../components/StaticPage/Hero";
import { StaticImage } from "gatsby-plugin-image";


const Contact: React.FC = () => {
  return (
    <DefaultLayout>
        <SEO
            title="Ensemble Platform | Ensemble"
            url="https://ensembleai.io/why-clickhouse-cloud"
            desc="Quickly Deploy Advanced Analytics And Generative AI On AWS"
            ogImage="https://ensembleai.io/assets/img/home.png"
        />
        <Hero
            title="Ensemble Platform"
            backgroundColor={["#40b7de", "#1976e0"]}
            description="Quickly Deploy Advanced Analytics And Generative AI On AWS"
        />

        <section className="px-6 max-w-[1188px] mx-auto space-y-8 my-16 lg:space-y-20">
            <div className="flex flex-col-reverse lg:flex-row-reverse justify-between gap-8 lg:gap-[70px] items-center">
                <StaticImage
                    className="max-w-[570px] w-full"
                    src="../images/why-clickhouse-cloud/why-clickhouse-cloud.png"
                    alt="Cloud Based Data and Analytics"
                    objectFit="contain"
                />
                <div className="space-y-[18px] lg:space-y-6 lg:max-w-[500px] w-full">
                    <h2 className="text-[28px] lg:text-[31px] font-bold gradient-blue-text ">
                        The <span className="underimage gradient-blue-text ">Fastest Route</span> To An AI Enabled Business
                    </h2>
                    <p className="text-base">Quantum is our AWS based data platform which allows you to rapidly deploy advanced real-time analytics and AI/ML enabled applications in your business.</p>  
                    <p className="text-base">It allows you to quickly deploy a high performance data warehouse and perform real time analytics over large and rapidly changing datasets - whilst being designed for best practices including performance, cost control and security.</p>  
                    <p className="text-base">It also incorporates our real-time user interface framework <a className="underline" href="/blog/introducing-ensemble-js">EnsembleJS</a>, allowing you to quickly build line of business and customer facing applications with less effort and fast time to market.</p>  
                </div>
            </div>
        </section>
        
        <div className="mt-16 pt-6 max-w-[800px] px-3 text-center mx-auto">
            <h2 className="text-[28px] mb-8 lg:text-[31px] font-bold gradient-blue-text ">
            An <span className="underimage gradient-blue-text ">All In One</span> Cloud Native Data Platform
            </h2>
            <p>Rapidly deploy a modern analytics and AI environment that incorporates the following:</p>
        </div>
        <div className="p-6 mt-4 mb-16 lg:pb-10 gap-[1rem] gap-y-[1.2rem] max-w-[1190px] flex justify-center flex-wrap mx-auto">
            <div 
                className="
                    md:max-w-[calc(50%-0.67rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-8 flex gap-4 flex-col md:flex-row items-start rounded-2xl 
                "
                style={{background: 'linear-gradient(to right, rgb(134, 118, 232), rgb(89, 86, 196))'}}
            >
                <img src="/assets/icons/fast.svg" alt="" width="64" height="" loading="lazy" />
                <div>
                    <h3 className="text-[20px] font-bold mb-4">Real Time Analytics</h3>
                    <p>Deploy advanced streaming analytics that give a real-time view of your world and continually advise you of your next best action.</p>
                </div>
            </div>

            <div 
                className="
                    md:max-w-[calc(50%-0.67rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-8 flex gap-4 flex-col md:flex-row items-start rounded-2xl 
                "
                style={{background: 'linear-gradient(to right, rgb(244, 159, 82), rgb(229, 126, 32))'}}
            >
                <img src="/assets/icons/simple.svg" alt="" width="64" height="" loading="lazy" />
                <div>
                    <h3 className="text-[20px] font-bold mb-4">Real Time Interactive Applications</h3>
                    <p>Deploy real-time interactive applications that empower your employees and delight your customers.</p>
                </div>
            </div>

            <div 
                className="
                    md:max-w-[calc(50%-0.67rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-8 flex gap-4 flex-col md:flex-row items-start rounded-2xl 
                "
                style={{background: 'linear-gradient(to right, rgb(82, 186, 193), rgb(63, 147, 193))'}}
            >
                <img src="/assets/icons/scalable.svg" alt="" width="64" height="" loading="lazy" />
                <div>
                    <h3 className="text-[20px] font-bold mb-4">Data Science & Machine Learning</h3>
                    <p>Incorporate data science and machine learning driven solutions that incorporate predictive and statistical analysis.</p>
                </div>
            </div>

            <div 
                className="
                    md:max-w-[calc(50%-0.67rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-8 flex gap-4 flex-col md:flex-row items-start rounded-2xl 
                "
                style={{background: 'linear-gradient(to right, rgb(223, 106, 154), rgb(230, 77, 105))'}}
            >
                <img src="/assets/icons/open-source.svg" alt="" width="64" height="" loading="lazy" />
                <div>
                    <h3 className="text-[20px] font-bold mb-4">Analytics Environments</h3>
                    <p>Give your data analysts and data scientists access to powerful analytics environments that allow them to analyse data and train machine learning models.</p>
                </div>
            </div>

            <div 
                className="
                    md:max-w-[calc(50%-0.67rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-8 flex gap-4 flex-col md:flex-row items-start rounded-2xl 
                "
                style={{background: 'linear-gradient(to right, rgb(212, 121, 204), rgb(194, 91, 210))'}}
            >
                <img src="/assets/icons/cost-effective.svg" alt="" width="64" height="" loading="lazy" />
                <div>
                    <h3 className="text-[20px] font-bold mb-4">Large Language Models</h3>
                    <p>Use large language models to process unstructured data, deploy chatbots and copilots, and intelligently automate business processes.</p>
                </div>
            </div>

            <div 
                className="
                    md:max-w-[calc(50%-0.67rem)] md:min-w-[calc(50%-0.5rem)]
                    min-w-full max-w-full p-8 flex gap-4 flex-col md:flex-row items-start rounded-2xl 
                "
                style={{background: 'linear-gradient(to right, rgb(122, 216, 155), rgb(75, 185, 144))'}}
            >
                <img src="/assets/icons/secure.svg" alt="" width="64" height="" loading="lazy" />
                <div>
                    <h3 className="text-[20px] font-bold mb-4">Best Practices</h3>
                    <p>Designed for all best practices including performance, cost control and security.</p>
                </div>
            </div>
        </div>

    </DefaultLayout>
  );
};

export default Contact;
